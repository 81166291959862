import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const AppCustomersReviewSectionWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 0px;
  margin-bottom: 50px;
  text-align: center; /* Center the heading and slider */

  .HomeCustomersReviewContain {
    max-width: 95vw;
    margin: auto;
    background-image: linear-gradient(to top, #e3f0ff 0%, #f3e7e9 99%, #e3f0ff 100%);
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 42px;
    display: flex;
  }

  .sliderContainer {
    width: 80vw; /* Adjusted width to fit content */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
  }

  .sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    display: flex; /* Use Flexbox to align content side by side */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    min-width: 100%; /* Full width for each slide */
    box-sizing: border-box;
    color: #fff;
    text-align: left; /* Align text to the left */
    /* flex-direction: row-reverse; */
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .slideContent {
    flex: 1;
    text-align: center;
    padding-left: 2rem; /* Add space between image and text */
  }

  .HomeCustomersReview-sliderImage {
    width: 10vw; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
    border: 4px solid #ff8095;
    border-radius: 220px;
    @media (min-width: 1800px) {
      width: 21vw;
    }
  }
  .HomeCustomersReview-sliderAnchor {
    width: auto; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
  }

  .HomeCustomersReviewHeading {
    color: #1f1f1f;
    font-size: 2rem;
    margin: 1rem 0rem 1rem 0rem;
    font-weight: bolder;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
  }
  /* .HomeCustomersReviewHeadingTwo {
    color: #1f1f1f;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    padding-top: 12px;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
  }

  .HomeCustomersReviewSubheading {
    color: #4e4e4e;
    margin-bottom: 0.5rem;
  } */

  .HomeCustomersReviewPara {
    padding-top: 32px;
    color: #1f1f1f;
    margin-bottom: 1rem;
    line-height: 28px;
    font-size: 1.1rem;
  }

  .dots {
    text-align: center;
    padding-top: 10px;
  }

  /* .dot {
    height: 9px;
    width: 9px;
    margin: 0 5px;
    border: 2px solid #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease, transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
      background-color: #bbb;
    }
  } */

  .active {
    background-color: #bbb;
    transform: scale(1.4);
  }
`

export const AppCustomersReviewSection = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const slides = [
    {
      heading: 'Terri Smith',
      headingTwo: '',
      subheading: '',
      para: '"Our team has been using the Raindrop Contract and Sourcing modules for a few years and it has completely transformed our procurement process. The user-friendly interface, combined with powerful search capabilities, has allowed us to find the best suppliers quickly and efficiently. With Raindrop, we are able to take sourcing processes that used to take a lot of manual effort and automate them, avoiding human error, and ultimately allow us to focus on more strategic, value-added activities. I highly recommend this tool to any business looking to optimize their sourcing strategy!"',
      imageSrc: 'https://storage.googleapis.com/raindroppublic/website_data/World_Market_Customer_logo.png',
      link: 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAACTViQ4B-thrMnYVpKmATKJhIB5w4Omoik4&keywords=nikhil%20gaur&origin=RICH_QUERY_SUGGESTION&position=0&searchId=b12af901-e884-426c-8b0b-40a937a7e213&sid=5PP&spellCorrectionEnabled=false', // Add link here
    },
  ]

  const numOfSlides = slides.length

  // Automatically change slides every 5 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % numOfSlides)
  //   }, 20000)

  //   return () => clearInterval(interval)
  // }, [numOfSlides])

  // const handleDotClick = (index: number) => {
  //   setCurrentSlideIndex(index)
  // }

  return (
    <AppCustomersReviewSectionWpper>
      <div className="HomeCustomersReviewContain">
        <div className="sliderContainer">
          <div
            className="sliderWrapper"
            style={{
              transform: `translateX(-${currentSlideIndex * 100}%)`, // Full width for each slide
            }}
          >
            {slides.map((slide, i) => (
              <div className="slide" key={i}>
                <a
                  href={slide.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="HomeCustomersReview-sliderAnchor"
                >
                  <img src={slide.imageSrc} alt={`Slide ${i + 1}`} className="HomeCustomersReview-sliderImage" />
                </a>
                <div className="slideContent">
                  <div className="HomeCustomersReviewHeading">{slide.heading}</div>
                  <div className="HomeCustomersReviewHeadingTwo">{slide.headingTwo}</div>
                  <div className="HomeCustomersReviewSubheading">{slide.subheading}</div>
                  <div className="HomeCustomersReviewPara">{slide.para}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="dots">
          {Array.from({ length: numOfSlides }).map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlideIndex ? 'active' : ''}`}
              // onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </AppCustomersReviewSectionWpper>
  )
}
